/* medienhaus.css */

@import url("./normalize.css");
@import url("./fonts.css");

:root {
  --color-fg: rgb(255, 255, 255);
  --color-bg: rgb(0, 0, 0);
  --color-hi: rgb(228, 9, 59);
  --color-lo: rgb(240, 240, 240);
  --color-ft: rgb(0, 0, 0, 0.3);
  --margin: calc(1vw + 1vh + 0.5vmin);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: unset;
  padding: unset;
}

*::selection {
  background-color: var(--color-lo);
}

html {
  background-color: var(--color-bg);
  color: var(--color-fg);
  font-family: "Cosmos", sans-serif;
  font-size: calc(1vw + 1vh + 0.5vmin);
  font-style: normal;
  font-weight: 500;
  height: 100%;
  line-height: 1.4;
  scroll-behavior: smooth;
}

body {
  font-size: 1rem;
  height: 100%;
  padding-left: calc(var(--margin) * 2);
  padding-right: calc(var(--margin) * 2);
  opacity: 0;
  animation: init 0.03s ease-in 0.5s 1 forwards;
}

body>#app>*:not(footer)>*>*+* {
  margin-top: calc(var(--margin) * 2);
}

body>#app>main> :first-of-type {
  margin-top: calc(var(--margin) * 0.6);
}

body>#app>main>section+section {
  margin-top: calc(var(--margin) * 4);
}

a:link {
  color: var(--color-fg);
  text-decoration: none;
}

a:visited {
  color: var(--color-fg);
}

a:hover {
  color: var(--color-fg);
  text-decoration: underline;
}

a:focus {
  color: var(--color-fg);
}

a:active {
  color: var(--color-fg);
}

/*
a.active {
  color: var(--color-ft);
}
*/

em,
i {
  font-style: italic;
}

/*
figure,
figure > img {
  width: 100%;
}
*/

/*
footer {
  overflow: auto;
}
*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: unset;
}

iframe {
  border: none;
  height: calc(100% - calc(var(--margin) * 2));
  max-width: 100%;
  width: 100%;
}

strong,
b {
  font-weight: bold;
}

ul {
  list-style-type: none;
}

a[rel~="external"] {
  white-space: nowrap;
}

a[rel~="external"]::after {
  content: "\2197";
  display: inline-block;
  font-size: 0.6rem;
  font-weight: 900;
  vertical-align: super;
}

img[alt=""],
img:not([alt]) {
  border: 3px dashed rgb(255, 0, 0);
}

footer {
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--margin);
  justify-content: space-between;
  grid-row: 3;
  grid-column: 1 / 3;
  margin-top: calc(var(--margin) * 4);
  margin-bottom: var(--margin);
}

footer>img[src*=".svg"] {
  height: calc(var(--margin) * 2);
  max-width: 100%;
}

header {
  background-color: var(--color-bg);
  grid-row: 1;
  grid-column: 1;
  padding-top: calc(var(--margin) * 1.4);
  padding-bottom: calc(var(--margin) * 1.4);
  position: sticky;
  top: 0;
  z-index: 1;
}

header>a {
  font-weight: bold;
}

header>a>h1 {
  display: inline-block;
}

main {
  grid-row: 2;
  grid-column: 1;
}

nav {
  grid-row: 1 / 3;
  grid-column: 2;
  /*
  margin-top: calc(var(--margin) * 1.4);
  */
}

nav>div {
  position: sticky;
  top: calc(var(--margin) * 1.4);
}

nav>*>*>a+a {
  margin-top: calc(var(--margin) * 0.55);
}

nav a {
  display: block;
  font-weight: bold;
}

/*
section {
  scroll-margin-top: calc(var(--margin) * 6);
}
*/

section a:link {
  text-decoration: underline;
}

section h2,
section h3 {
  font-weight: bold;
}

section blockquote {
  font-size: 2.9rem;
  line-height: 1.2;
}

section ul {
  list-style-type: "\2013\0020";
  list-style-position: inside;
}

section ul li+li {
  margin-top: calc(var(--margin) * 0.5);
}

section#definition {
  box-sizing: content-box;
  display: grid;
  align-items: center;
  height: calc(100vh - calc(var(--margin) * 12));
  padding-top: calc(var(--margin) * 1);
  padding-bottom: calc(var(--margin) * 3);
}

form+*,
form>*+* {
  margin-top: calc(var(--margin) * 2);
}

form ::placeholder {
  color: var(--color-ft);
  opacity: unset;
}

form button {
  background-color: var(--color-fg);
  border-color: var(--color-fg);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
  color: var(--color-bg);
  cursor: pointer;
  padding: calc(var(--margin) * 0.2);
  width: 100%;
}

form button[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: unset;
  border-radius: unset;
  border-bottom-color: var(--color-fg);
  border-bottom-style: solid;
  border-bottom-width: calc(var(--margin) * 0.2);
  box-shadow: none;
  outline: none;
  padding: calc(var(--margin) * 0.2);
}

form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnIGZpbGw9IiMwMDAwMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHg9IjBweCIgeT0iMHB4Ij4gIDxwb2x5Z29uIHBvaW50cz0iNTAgNTcuMTMgMjMuMTkgMzAuNDYgMTYuMTMgMzcuNTUgNTAgNzEuMjMgODMuODYgMzcuNTUgNzYuODEgMzAuNDYgNTAgNTcuMTMiPjwvcG9seWdvbj48L3N2Zz4=);
  background-position: calc(100% - calc(var(--margin) * 0.2)) 55%;
  background-repeat: no-repeat;
  background-size: var(--margin);
  border: unset;
  border-radius: unset;
  border-bottom-color: var(--color-fg);
  border-bottom-style: solid;
  border-bottom-width: calc(var(--margin) * 0.2);
  box-shadow: none;
  outline: none;
  padding: calc(var(--margin) * 0.2);
  text-overflow: ellipsis;
}

form select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--color-fg);
}

form select:-moz-focusring * {
  color: var(--color-fg);
  text-shadow: none;
}

form textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-color: var(--color-fg);
  border-radius: unset;
  border-style: solid;
  border-width: calc(var(--margin) * 0.2);
  box-shadow: none;
  outline: none;
  padding: calc(var(--margin) * 0.4);
  resize: vertical;
  width: 100%;
}

#app {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: auto 1fr auto;
  grid-gap: 0 calc(var(--margin) * 2.8);
  min-height: 100%;
}

#loading {
  border-color: var(--color-fg) transparent var(--color-fg) transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: calc(var(--margin) * 0.2);
  height: 2rem;
  width: 2rem;
  animation: loading 2.4s linear infinite;
}

.account>div {
  display: grid;
  align-items: center;
  grid-gap: var(--margin);
  grid-template-columns: auto 1fr;
}

.account button {
  background-color: var(--color-hi);
  border-color: var(--color-hi);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
  color: var(--color-bg);
  cursor: pointer;
  padding: calc(var(--margin) * 0.2);
}

.account button[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.avatar {
  height: calc(var(--margin) * 2.8);
  width: calc(var(--margin) * 2.8);
}

.copy {
  max-width: 55ch;
}

.copy>*+* {
  margin-top: calc(var(--margin) * 1.4) !important;
}

.explore>*+div {
  margin-top: calc(var(--margin) * 1) !important;
}

.explore>div {
  display: grid;
  align-items: center;
  grid-gap: var(--margin);
  grid-template-columns: auto 1fr 11ch;
}

.explore>h2 {
  text-decoration: underline;
}

.explore button {
  background-color: var(--color-fg);
  border-color: var(--color-fg);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
  color: var(--color-bg);
  cursor: pointer;
  padding: calc(var(--margin) * 0.2);
  text-transform: uppercase;
  width: 100%;
}

.explore button[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.explore button>#loading {
  max-height: calc(var(--margin) * 1.15);
  max-width: calc(var(--margin) * 1.15);
  margin: 0 auto;
}

.faq>code {
  background-color: var(--color-lo);
  padding: 0 calc(var(--margin) * 0.2);
}

.faq button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-bg);
  border: unset;
  color: var(--color-fg);
  cursor: pointer;
  display: block;
  font-weight: bold;
  line-height: 1.4;
  text-align: left;
}

.kino {
  margin-top: calc(var(--margin) * 1) !important;
}

.kino .sidebar {
  font-size: 0.55rem;
}

.kino .sidebar button {
  background-color: var(--color-fg);
  border-color: var(--color-fg);
  border-style: solid;
  border-radius: unset;
  border-width: calc(var(--margin) * 0.2);
  color: var(--color-bg);
  cursor: pointer;
  display: inline-grid;
  place-content: center;
  height: calc(var(--margin) * 1.4);
  width: calc(var(--margin) * 1.4);
}

.kino .sidebar ul {
  list-style-type: none;
}

.kino .sidebar ul li {
  display: grid;
  grid-auto-flow: column;
  grid-gap: calc(var(--margin) * 0.5);
  align-items: center;
  justify-content: start;
}

.kino .sidebar .playicon {
  border-color: transparent transparent transparent var(--color-bg);
  border-style: solid;
  border-width: calc(var(--margin) * 0.2) 0 calc(var(--margin) * 0.2) calc(var(--margin) * 0.325);
  margin-left: calc(var(--margin) * calc(0.325 / 4));
  pointer-events: none;
}

.kino .sidebar .playicon.playing {
  border-color: var(--color-bg);
  border-width: calc(var(--margin) * 0.2) 0 calc(var(--margin) * 0.2) calc(var(--margin) * 0.4);
  margin-left: 0;
}

.l10n {
  display: grid;
  align-items: start;
  grid-auto-flow: column;
  grid-gap: calc(var(--margin) * 1);
}

.l10n>button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-bg);
  border: unset;
  color: var(--color-fg);
  cursor: pointer;
  line-height: 1.4;
}

.react-player {
  position: absolute;
  left: 0;
  top: 0;
}

.react-player__preview {
  background-color: var(--color-fg);
}

.react-player__play-icon {
  border-color: var(--color-bg);
}

.react-player__shadow {
  background: unset;
}

.video>div {
  position: sticky;
  top: calc(var(--margin) * 4.8);
}

.videoplayer {
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  left: 0;
  top: 0;
}

.videowrapper {
  padding-top: 56.25%;
  position: relative;
}

.videowrapper>progress:not([value]) {
  display: none;
}

.videowrapper>progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: unset;
  height: calc(var(--margin) * 0.4);
  width: 100%;
}

.videowrapper>progress[value]::-webkit-progress-value,
.videowrapper>progress[value]::-moz-progress-bar {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-fg);
  border: unset;
}

/*
.videowrapper > progress[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(-45deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%),
                    -webkit-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%),
                    -webkit-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%);
}

.videowrapper > progress[value]::-moz-progress-bar {
  background-color: var(--color-fg);
  background-image: -moz-linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%),
                    -moz-linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%),
                    -moz-linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%);
}
*/

/*
.with-sidebar {
  overflow: hidden;
}
*/

.with-sidebar>* {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--margin) / 2 * -1);
}

.with-sidebar>*>* {
  /*
  flex-basis: 30%;
  */
  flex-grow: 1;
  margin: calc(var(--margin) / 2);
}

.with-sidebar>*> :first-child {
  flex-basis: 0;
  flex-grow: 999;
  min-width: calc(70% - var(--margin));
}

@keyframes init {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* 479px */
@media (max-width: 29.938em) {
  form input {
    width: 100%;
  }

  form label+* {
    margin-top: calc(var(--margin) * 0.5);
  }

  form input[name="username"] {
      width: 100%;
  }

  form select {
    background-position: calc(100% - calc(var(--margin) * 0.6)) 55%;
    width: 100%;
  }

  form input,
  form select {
    border-color: var(--color-fg);
    border-style: solid;
    border-radius: unset;
    border-width: calc(var(--margin) * 0.2);
  }

  form button,
  form input,
  form select {
    padding: calc(var(--margin) * 0.6);
  }

  #login label[for="username"] {
    display: block;
  }

  #login input[name="username"] {
 
    width: 100%;
  }

  header {
    grid-column: 1 / 3;
  }

  main {
    grid-column: 1 / 3;
  }

  nav {
    display: none;
  }

  .copy {
    max-width: calc(100% - calc(var(--margin) * 0.7));
  }
}

/* 480px */
@media (min-width: 30em) {
  form div {
    display: grid;
    align-items: center;
    grid-gap: var(--margin);
    grid-template-columns: auto 1fr;
  }

  form input {
    min-width: 10ch;
  }

  #login div:first-of-type {
    grid-template-columns: 8ch auto max-content;
  }
}

/* 799px */
@media (max-width: 49.938em) {
  /*
  .kino .sidebar {
    font-size: 1rem;
  }
  .kino .sidebar button {
    height: calc(var(--margin) * 2.8);
    width: calc(var(--margin) * 2.8);
  }
  .kino .sidebar ul li {
    grid-gap: calc(var(--margin) * 1);
  }
  .kino .sidebar .playicon {
    border-width: calc(var(--margin) * 0.6) 0 calc(var(--margin) * 0.6) calc(var(--margin) * 0.975);
  }
  .kino .sidebar .playicon.playing {
    border-width: calc(var(--margin) * 0.6) 0 calc(var(--margin) * 0.6) calc(var(--margin) * 1.2);
  }
  */
}

/* 800px */
@media (min-width: 50em) {
  form {
    max-width: 55ch;
  }
}

/* 960px */
/*
@media (min-width: 60em) {
}
*/

/* fallback for list-style-type: "<string>"; */
@supports not (list-style-type: "\2013\0020") {

  /*
  section ul {
    list-style-type: square;
  }
  */
  section ul li::before {
    content: "\2013\0020";
  }
}