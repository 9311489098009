@font-face {
  font-display: block;
  font-family: "Inter";
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Inter-Medium.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: "Inter";
  font-stretch: normal;
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/Inter-MediumItalic.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: "Inter";
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Inter-Bold.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: "Inter";
  font-stretch: normal;
  font-style: italic;
  font-weight: bold;
  src: url("../fonts/Inter-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-display:block;
  font-family:"Cosmos";
  font-stretch:normal;
  font-style:normal;
  font-weight:500;
  src:url("../fonts/Cosmos-Medium.woff2") format("woff2");
}

@font-face {
  font-display:block;
  font-family:"Cosmos";
  font-stretch:normal;
  font-style:normal;
  font-weight:800;
  src:url("../fonts/Cosmos-ExtraBold.woff2") format("woff2");
}